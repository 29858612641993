const config = {
  siteName: 'Example Company',
  siteTitle: 'Example Company', // Site title.
  siteTitleShort: 'Example', // Short site title for homescreen (PWA). Preferably should be under 12 characters to prevent truncation.
  siteTitleAlt: 'Example Company', // Alternative site title for SEO.
  siteLogo: 'images/logo.png', // Logo used for SEO and manifest. Relative to /static
  siteUrl: 'https://examplecompany.com', // Domain of your website without pathPrefix.
  siteDescription: 'Example Company', // Website description used for RSS feeds/meta description tag.
  siteAuthor: 'Example Company', // Site author
  favIcon: 'src/images/favicon.ico',
  baseUrl: 'https://examplecompany.com',
  pageTitle: 'Documentation',
  menuTitle: 'Menu Title',
  subtitle: 'Example Company',
  description: 'A guide to using Example Company products',
  primaryColor: '#3E78BC',
};

module.exports = config;
